/* eslint-disable no-await-in-loop */
import BigNumber from 'bignumber.js';
import ReduxUtils from '../utils/ReduxUtils';
import TransactionHelper from '../utils/TransactionHelper';

export const UPDATE_LEDGER_STATUS = 'UPDATE_LEDGER_STATUS';

export const UPDATE_LEDGER_ACCOUNTS = 'UPDATE_LEDGER_ACCOUNTS';

export const updateLedgerStatus = ReduxUtils.createAction(
  UPDATE_LEDGER_STATUS,
  'status',
);

export const updateLedgerAccounts = ReduxUtils.createAction(
  UPDATE_LEDGER_ACCOUNTS,
  'accounts',
);

let timer = null;
export const subscribeToLedgerStatus = () => async (dispatch, getState) => {
  if (!timer) {
    timer = setInterval(async () => {
      if (!timer) return;
      console.log('waiting for ledger...');
      const { ledger } = getState();
      const status = await TransactionHelper.isLedgerConnected();
      if (ledger.status !== status) {
        dispatch(updateLedgerStatus(status));
      }
    }, 5000);
  }
};
export const unsubscribeToLedgerStatus = () => async () => {
  clearInterval(timer);
  timer = null;
};

export const fetchLedgerAccounts = (page = 0, pageSize = 5) => async (
  dispatch,
  getState,
  { apiService },
) => {
  dispatch(updateLedgerAccounts(null));
  try {
    const accounts = await TransactionHelper.getLedgerAccounts(
      page * pageSize,
      pageSize,
    );
    const accountsWithBalance = [];
    for (let i = 0; i < accounts.length; i += 1) {
      const account = accounts[i];
      let bal;
      try {
        bal = await apiService.fetchBalance(account.address);
        const bn = new BigNumber(bal.result);
        const value = bn.shiftedBy(-18);
        accountsWithBalance.push({ ...account, balance: value });
      } catch (ex) {
        console.trace(ex);
        accountsWithBalance.push({ ...account, balance: new BigNumber(0) });
      }
    }
    dispatch(updateLedgerAccounts(accountsWithBalance));
  } catch (ex) {
    console.trace(ex);
  }
};
