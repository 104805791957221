/* eslint-disable import/extensions */
/* eslint-disable camelcase */
import TransportU2f from '@ledgerhq/hw-transport-u2f';
import { AionApp } from 'lib-hw-ledger-js';
import rlp from 'aion-rlp';
import { signTransaction } from 'makkii-coins/coins/aion/keystore/transaction.js';
import { keystoreClient } from 'makkii-coins';
import { validator } from 'lib-common-util-js';
import { numberToHex, toAionLong } from './utils';

const client = keystoreClient(['AION'], true);

export default class TransactionHelper {
  static buildTransaction(
    nonce,
    from,
    to,
    gasPrice,
    gasLimit,
    private_key,
    data,
    amount,
  ) {
    return {
      nonce,
      from,
      to,
      amount: amount || 0,
      timestamp: new Date().getTime() * 1000,
      type: 1,
      gasPrice,
      gasLimit,
      private_key,
      data,
    };
  }

  static sign(unsigned_tx, accountModel) {
    // todo: refactor to async
    return new Promise((resolve, reject) => {
      switch (accountModel.type) {
        // ledger
        case 'ledger':
          TransportU2f.create().then(
            (transport) => {
              const wallet = new AionApp(transport);
              const { index } = accountModel;
              wallet.getAccount(index).then(
                (account) => {
                  const unsigned = this.toRLPUnsigned(unsigned_tx);
                  wallet.sign(index, unsigned).then(
                    (signature) => {
                      const full_signature = Buffer.concat([
                        Buffer.from(account.pubKey, 'hex'),
                        // Buffer.from(signature.substring(0,
                        //   Math.min(signature.length - 4, 128)), 'hex')
                        Buffer.from(signature, 'hex'),
                      ]);
                      const signed = rlp
                        .decode(unsigned)
                        .concat(full_signature);
                      resolve(rlp.encode(signed).toString('hex'));
                    },
                    (err) => reject(err),
                  );
                },
                (err) => reject(err),
              );
            },
            (err) => reject(err),
          );
          break;

        // mnemonic, private key, keystore
        default:
          signTransaction(unsigned_tx).then(
            (signed) => resolve(signed.encoded),
            (err) => reject(err),
          );
          break;
      }
    });
  }

  static toRLPUnsigned(unsigned_tx) {
    /* eslint-disable no-param-reassign */
    unsigned_tx.nonce = numberToHex(unsigned_tx.nonce);
    unsigned_tx.to = unsigned_tx.to.toLowerCase();
    unsigned_tx.amount = numberToHex(unsigned_tx.amount);
    unsigned_tx.gasLimit = toAionLong(numberToHex(unsigned_tx.gasLimit));
    unsigned_tx.gasPrice = toAionLong(numberToHex(unsigned_tx.gasPrice));
    unsigned_tx.type = toAionLong(unsigned_tx.type);

    return rlp.encode([
      unsigned_tx.nonce,
      unsigned_tx.to,
      unsigned_tx.amount,
      unsigned_tx.data,
      unsigned_tx.timestamp,
      unsigned_tx.gasLimit,
      unsigned_tx.gasPrice,
      unsigned_tx.type,
    ]);
  }

  static isLedgerConnected() {
    return new Promise((resolve) => {
      TransportU2f.create().then(
        (transport) => {
          new AionApp(transport).getAccount(0).then(
            () => {
              resolve(true);
            },
            () => {
              resolve(false);
            },
          );
        },
        () => {
          resolve(false);
        },
      );
    });
  }

  static async getLedgerAccounts(start = 0, count = 5) {
    const accounts = [];
    for (let i = 0; i < count; i += 1) {
      accounts.push(this.getLedgerAccount(start + i));
    }
    return Promise.all(accounts);
  }

  static getLedgerAccount(i) {
    return new Promise((resolve, reject) => {
      TransportU2f.create().then(
        (transport) => {
          new AionApp(transport).getAccount(i).then(
            (account) => {
              if (account.address.length > 66) {
                account.address = account.address.substring(0, 66);
              }
              account.index = i;
              resolve(account);
            },
            (err) => reject(err),
          );
        },
        (err) => reject(err),
      );
    });
  }

  static accountFromPrivateKey(privateKey) {
    return new Promise((resolve, reject) => {
      client.recoverKeyPairByPrivateKey(privateKey, 'aion').then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  static accountFromKeystore(fileBuffer, password) {
    return new Promise((resolve, reject) => {
      client
        .recoverFromKeystore('aion', fileBuffer, password)
        .then((res) => {
          resolve({
            privateKey: res.privateKey,
            pubKey: res.publicKey,
            address: res.address,
          });
        })
        .catch((err) => {
          console.log(err);
          const msg =
            typeof err === 'string' ? err : 'Keystore file is invalid';
          reject(msg);
        });
    });
  }

  static isMnemonicValid(wordsString) {
    return validator.validateMnemonic(wordsString);
  }

  static accountFromMnemonic(wordsString, accountIndex = 0) {
    return new Promise((resolve, reject) => {
      client
        .getKeyFromMnemonic('aion', accountIndex, wordsString)
        .then((res) => {
          resolve({
            privateKey: res.private_key,
            pubKey: res.public_key,
            address: res.address,
          });
        })
        .catch((err) => {
          console.log(err);
          const msg =
            typeof err === 'string' ? err : 'Mnemonic recovery failed';
          reject(msg);
        });
    });
  }
}
