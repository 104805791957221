import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Tabs } from 'antd';
import ContentLoader from 'react-content-loader';
import { useLocation } from 'react-router-dom';
import {
  text, sizes, elements, strings,
} from '../constants';
import HomeStakingSection from '../components/HomeStakingSection';
import HomeStakesList from '../components/HomeStakesList';
import PoolsList from '../components/PoolsList';
import { sendAmplitudeData } from '../utils/Amplitude';

const { TabPane } = Tabs;

const styles = {
  appTitle: {
    ...text.TITLE_LARGE,
    marginBottom: sizes.SPACE_MEDIUM,
  },
  walletCard: {
    ...elements.CARD,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '0',
    paddingBottom: '0',
  },
  cardHeading: {
    ...text.HEADING_MEDIUM,
    flexGrow: '1',
    marginLeft: sizes.SPACE_SMALL,
    marginRight: sizes.SPACE_SMALL,
  },
  tabs: {
    paddingLeft: sizes.SPACE_SMALL,
    paddingRight: sizes.SPACE_SMALL,
  },
};

export default function Staking() {
  const pools = useSelector((state) => state.pools);
  const [filter, setFilter] = useState();

  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(location.search === '?tab=2' ? '2' : '1');

  return (
    <div>
      {sendAmplitudeData(strings.VIEW_STAKING)}
      {(!pools.data && pools.loading) && (
      <div>
        <ContentLoader viewBox="0 0 100 80">
          <rect x="0" y="0" rx="1" ry="1" width="10" height="3" />
          <rect x="0" y="10" rx="2" ry="2" width="32" height="13" />
          <rect x="33" y="10" rx="2" ry="2" width="32" height="13" />
          <rect x="66" y="10" rx="2" ry="2" width="32" height="13" />
          <rect x="0" y="30" rx="2" ry="2" width="100" height="80" />
        </ContentLoader>
      </div>
      )}
      {pools?.data && (
        <>
          <div style={styles.appTitle}>Staking</div>
          <HomeStakingSection
            onStake={() => {
              setActiveTabKey('2');
              setFilter(3);
            }}
            onUnstake={() => {
              setActiveTabKey('1');
              setFilter(3);
            }}
            onWithdraw={() => {
              setActiveTabKey('1');
              setFilter(4);
            }}
          />
          <Card bodyStyle={styles.walletCard}>
            <Tabs
              activeKey={activeTabKey}
              className="my-tabs"
              tabBarStyle={styles.tabs}
              size="large"
              key
              onChange={(key) => setActiveTabKey(key)}
            >
              <TabPane tab="My Stakes and Pools" key="1">
                <HomeStakesList filterIndex={filter} onStartStaking={() => setActiveTabKey('2')} />
              </TabPane>
              <TabPane tab="All Pools" key="2">
                <PoolsList />
              </TabPane>
            </Tabs>
          </Card>
        </>
)}
    </div>
  );
}
